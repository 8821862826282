import React from "react";
import balanceCoin from "../../images/mui/tokens.svg";
import { useDispatch, useSelector } from "react-redux";
import CusButton from "../mui/CusButton";
import { ENUM_MODALS_VALUE, setModals } from "../../store/commonSlice";
import { useTranslation } from "react-i18next";
import ShowContainer from "../mui/ShowContainer";

export default function BalanceInfo({ maxSize = false, closeModal, minSize = false }) {
  const dispatch = useDispatch();
  const balanceValue = useSelector((state) => state.user.user.balance);
  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);
  const { t } = useTranslation();

  const onDepositClick = () => {
    if (isFullScreen) {
      document?.exitFullscreen?.();
    }

    closeModal?.();
    dispatch(setModals({ name: ENUM_MODALS_VALUE.deposit, value: true }));
  };
  return (
    <div className="balance__content" onClick={onDepositClick}>
      <span className="balance__value">
        <img src={balanceCoin} />
        <span>{balanceValue ?? 0}</span>
        <span className="tk">{t("tk")}</span>
      </span>

      <ShowContainer condition={!minSize}>
        {maxSize ? (
          <CusButton className="custom-button__red" text={"Deposit"} onClick={onDepositClick} />
        ) : (
          <CusButton
            className="custom-button__red mini-button"
            text={"+"}
            onClick={onDepositClick}
          />
        )}
      </ShowContainer>
    </div>
  );
}
