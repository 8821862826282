import { scroller } from "react-scroll";
import { PREFIX } from "../api/main";

export const sleep = async (ms) => new Promise((rs) => setTimeout(rs, ms));

// getAutoShortLang - получение языка по браузеру
export const getAutoShortLang = () => {
  const langBrowserFull = navigator.language || navigator?.userLanguage;
  const langMiniBrowser = langBrowserFull.substring(0, 2)?.toLowerCase();
  const langBrowser = langMiniBrowser || "en";
  return langBrowser;
};

//getShortLang - получение языка из локалсторадже
export const getShortLang = () => {
  const code = getCookie("language")?.split("_")?.[0];
  if (code && code !== "undefined") return code;

  const configLang = window.startConfig?.language?.code;
  const serverLang = configLang?.substring(0, 2)?.toLowerCase?.();
  if (serverLang) return serverLang;

  const langBrowser = getAutoShortLang();
  return langBrowser;
};

// removeDuplicates - удаление из массивов  дубликатов
export const removeDuplicates = (array) => {
  let uniqueLabels = {};

  let result = array.filter((obj) => {
    if (!uniqueLabels[obj.label]) {
      uniqueLabels[obj.label] = true;
      return true;
    }
    return false;
  });

  return result;
};

// getBase64 - получение строки басе64 из файла
export function getBase64(file, isFull = false) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (isFull) resolve(reader.result);
      resolve(reader.result.split(",")[1]);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
}

// getRandomItemInList - выбор рандомного значения сервера
export const getRandomItemInList = (list, defaultValue) => {
  try {
    if (!Array.isArray(list)) return defaultValue;
    const randomIndex = Math.floor(Math.random() * list.length);
    return list[randomIndex];
  } catch (e) {
    console.log(e);
    return defaultValue;
  }
};

// getCHPYParamByName -  парсинг параметра по измени из  ЧПУ
export function getCHPYParamByName(name) {
  const pathname = window.location.pathname;
  return pathname.split(name + ":")?.[1]?.split("/")?.[0];
}

// toggleElemInList - удаление или добавка в массив, возвращает новый массив, не изменяя старый
export const toggleElemInList = (list, id) =>
  list.includes(id) ? list.filter((el) => el !== id) : [...list, id];

// getUniqueList - возвращает массив только с уникальными объектами
export const getUniqueList = (list, field) =>
  list && field
    ? Array.from(new Set(list.map((mic) => mic[field]))).map((el) =>
        list.find((mic) => mic[field] === el)
      )
    : list;

//downloadFile - скачивает файлы создав ссылку
export function downloadFile(url, fileName) {
  var link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// deleteParamsUrl - удаляет какой то параметр из урл
export function deleteParamsUrl(param) {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  url.searchParams.delete(param);
  const modifiedUrl = url.toString();
  console.log("clear");
  window.history.pushState({}, "", modifiedUrl);
  return modifiedUrl;
}

// getScrollBottom - получение отступа снизу
export const getScrollBottom = (ref) => ref.scrollHeight - ref.scrollTop - ref.clientHeight;

// setElemHeight - установка высоты элементу
export const setElemHeight = (elem, newHeight) =>
  elem ? (elem.style.height = newHeight + "px") : undefined;

// getFsStatus - включен ли фуллскрин или нет
export const getFsStatus = () =>
  document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement;

export const requestFs = async (el, el2) => {
  if (document.fullscreenEnabled) {
    await el.requestFullscreen();
    return true;
  }
  if (document.webkitFullscreenEnabled) {
    await el.webkitRequestFullscreen();
    return true;
  }
  if (document.mozFullScreenEnabled) {
    await el.mozRequestFullScreen();
    return true;
  }
  if (document.msFullscreenEnabled) {
    await el.msRequestFullscreen();
    return true;
  }

  // если не получилось основной элемент пытаемся запасной

  if (!el2) return;

  try {
    if (el2.webkitRequestFullscreen) await el2.webkitRequestFullscreen();
    if (el2.webkitEnterFullScreen) await el2.webkitEnterFullScreen();
    if (el2.requestFullscreen) await el2.requestFullscreen();
    if (el2.mozRequestFullScreen) await el2.mozRequestFullScreen();
    if (el2.msRequestFullscreen) await el2.msRequestFullscreen();
  } catch (e) {
    console.log(e);
  }
};
export const cancelFs = async (i = 0) => {
  try {
    if (i == 0) document.exitFullscreen();
    if (i == 1) document.webkitExitFullscreen();
    if (i == 2) document.mozCancelFullScreen();
    if (i == 3) document.msExitFullscreen();
  } catch (e) {
    console.log(e);
    cancelFs(i + 1);
  }
};

// toDataURL - возвращает base64
export function toDataURL(url, callback, error = () => {}) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onabort = error;
    reader.onloadend = function () {
      const result = reader.result;

      if (result.includes("data:text/html;") || result.includes("data:text/xml;")) {
        error();
        return;
      }

      callback(result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";

  try {
    xhr.send();
  } catch (e) {
    error(e);
  }
}

// getAvatar - возвращает урл для аватар
export const getUrlAvatar = (useId) => `${PREFIX}/api/user/${useId}/avatar/256/`;

// scrollToElement- плавная прокрутка до элемента
export const scrollToElement = (elementName) => {
  scroller.scrollTo(elementName, { duration: 800, smooth: "easeInOutQuart" });
};

export const getImgById = (useId) => `/api/user/${useId}/avatar/256/`;

// generateResolutions - генерация популярных разрешение
export const generateResolutions = (minWidth = 640, maxWidth = 1920) => {
  const aspectRatios = [
    { width: 16, height: 9 },
    { width: 4, height: 3 },
  ];

  const resolutions = [];

  aspectRatios.forEach((ratio) => {
    resolutions.push(
      { width: 1920, height: Math.round((1920 / ratio.width) * ratio.height) },
      { width: 1280, height: Math.round((1280 / ratio.width) * ratio.height) },
      { width: 640, height: Math.round((640 / ratio.width) * ratio.height) }
    );
  });

  return resolutions.filter((el) => el.width >= minWidth && el.width <= maxWidth);
};

// makeResolution - возвращает массив разрешений
export const makeResolution = (mediaStream) => {
  try {
    const getLabel = (el) => el.width + "x" + el.height;

    const capabilitiesWidth = mediaStream.getVideoTracks()[0]?.getCapabilities?.()?.width;
    const resolutions = generateResolutions(capabilitiesWidth?.min, capabilitiesWidth?.max);
    return resolutions.map((el) => ({
      label: getLabel(el),
      value: getLabel(el),
    }));
  } catch (e) {
    console.error("not support resolution", e);
    return [];
  }
};

// getOptionsVideo - получение вариантов для камеры и микрофона
export const getOptionsVideo = async () => {
  // TEST UP ||

  const devices = await navigator.mediaDevices.enumerateDevices();

  const allVideoDevices = devices.filter((device) => device.kind === "videoinput");
  const allAudioDevices = devices.filter((device) => device.kind === "audioinput");
  const videoDevices = getUniqueList(allVideoDevices, "groupId");
  const audioDevices = getUniqueList(allAudioDevices, "groupId");

  const videoOptions = videoDevices.map((el, indx) => ({
    label: el.label || "video " + +indx + 1,
    value: el.deviceId,
  }));

  const audioOptions = audioDevices.map((el, indx) => ({
    label: el.label || "microphone " + +indx + 1,
    value: el.deviceId,
  }));

  return { videoOptions, audioOptions };
};

// insertCharAt - вставить символ в строку
export function insertCharAt(str, char, position) {
  return [str.slice(0, position), char, str.slice(position)].map((el) => el);
}

// subtractYears - вычитаем из текущий даты возраст
export function subtractYears(years) {
  const date = new Date();
  date.setFullYear(date.getFullYear() - years);
  return date;
}

//popupWindow - открыть в новом окне ссылку
export function popupWindow(url, w, h, closeCb) {
  const windowName = "mozillaWindow";
  const win = window;
  const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
  const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
  const newWindow = win.open(
    url,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
  );

  const checkWindowClosed = setInterval(() => {
    if (newWindow.closed) {
      clearInterval(checkWindowClosed);
      closeCb?.();
    }
  }, 500);

  return newWindow;
}

// checkDevServer - проверка на дев сервер
export const checkDevServer = () => window.location.host.search("dev") >= 0;

// setCookie - установка в куки
export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
  // document.cookie = name + "=" + (value || "") + expires;
}

// getCookie - получение куки
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function getTimeWitchUserTZ(date) {
  if (!date) return "";
  // Создаем объект Date, передав строку времени в формате UTC
  const utcDate = new Date(date);
  // Получаем локальное время
  const localDate = utcDate.toLocaleString(); // Вывод в локальном формате
  return localDate;
}

//dispatchCusEvent - кинуть пользовательское событие
export const dispatchCusEvent = (eventName, detail) => {
  window.dispatchEvent(new CustomEvent(eventName, { detail: detail }));
};
