import React from "react";

export default function Spinner({ className = "" }) {
  return (
    <div className={`spinner lds-roller ${className}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
