import React, { useCallback, useEffect } from "react";
import { useEventListener } from "usehooks-ts";
import { useSelector } from "react-redux";
import { ENUM_ELEMENT_NAME } from "../../common/enums";

// если разница между значениями когда скрывать/показывать небольшая то нечего не делаем
const DELAY_PX = 10;
const fixedClass = "fixed";

export default function ChatBottomFixed({ ChatSendRef, className, ChatSendSendRef, children }) {
  const isMobile = useSelector((state) => state.deviceSize.isMobile);
  const isFullScreen = useSelector((state) => state.common.flags.foolScreen);

  const scrollTimeout = useCallback(() => {
    const innerHeight = window.innerHeight;
    const scrollY = window.scrollY;

    const bottomSection = document.getElementsByName(ENUM_ELEMENT_NAME.streamProfile)?.[0];
    if (!bottomSection) return;
    const stopFixed = bottomSection?.offsetTop;
    const sendTop = innerHeight + scrollY + DELAY_PX;
    const isFixed = sendTop - stopFixed;

    // игнорируем барьер около нескольних px
    if (Math.abs(isFixed) < DELAY_PX) return;

    if (isFixed < 0) {
      ChatSendRef.current?.classList?.add?.(fixedClass);
    } else {
      ChatSendRef.current?.classList?.remove?.(fixedClass);
    }
  }, [ChatSendSendRef, ChatSendRef]);

  useEventListener("scroll", scrollTimeout);
  useEventListener("dragSize", scrollTimeout);
  useEventListener("resize", scrollTimeout);

  useEffect(() => {
    if (!isMobile) return;
    scrollTimeout();
  }, [isFullScreen, isMobile]);

  useEffect(() => {
    setTimeout(scrollTimeout, 40);
    setTimeout(scrollTimeout, 120);
    setTimeout(scrollTimeout, 500);
  }, []);

  const onSendClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    scrollTimeout();
  };

  return (
    <div className={className} onClick={onSendClick} ref={ChatSendSendRef}>
      {children}
    </div>
  );
}
