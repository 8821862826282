import { DatePicker } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

export default function CusDatePicker({
  label,
  onChange,
  setDate,
  defaultValue,
  value,
  disabledDate,
}) {
  const { t } = useTranslation();

  return (
    <div className="container-date-picker">
      <label className="container-date-picker__label">{t(label)}</label>
      <DatePicker
        disabledDate={disabledDate}
        className="cus-date-picker"
        placeholder={t("Select date")}
        popupClassName="popup_cus-date-picker"
        defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
        value={value ? dayjs(value) : undefined}
        onChange={(e) => {
          const date = e?.format("YYYY-MM-DD");
          if (setDate) setDate(date);
          if (onChange) onChange(e);
        }}
      />
    </div>
  );
}
