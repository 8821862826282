import React, { useEffect, useMemo, useRef, useState } from "react";
import CusSelect from "../mui/CusSelect";
import CusButton from "../mui/CusButton";
import CusDatePicker from "../mui/CusDatePicker";
import IconTimeCircle from "../../images/jsIcons/IconTimeCircle";
import StatisticEmpty from "./StatisticEmpty";
import StatisticTable from "./StatisticTable";
import Spinner from "../mui/Spinner";
import { API_PROFILE } from "../../api/profile";
import { useTranslation } from "react-i18next";
import { TEST_MODE } from "../common/consts";
import { removeDuplicates } from "../../utils/utils";
import { dataServer } from "../../testConst";
import ShowContainer from "../mui/ShowContainer";
import { useSelector } from "react-redux";

const serviceOptions = [
  { label: "All service", value: undefined },
  { label: "Deposits", value: "package-buy" },
  { label: "Private chat", value: "private-chat" },
  { label: "Exclusive chat", value: "exclusive-chat" },
  { label: "Paid chat", value: "paid-chat" },
  { label: "View chat", value: "view-chat" },
  { label: "Tip chat", value: "direct-transfer" },
  { label: "Tips (List)", value: "tips-list" },
  { label: "Tips (Lovense)", value: "tips-lovense" },
  // { label: "Target chat", value: "target-chat" },
  { label: "Balance correction", value: "balance-correction" },
];

export default function ContentStatistic() {
  const { t } = useTranslation();

  const height = useSelector((state) => state.deviceSize.height);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loader, setLoader] = useState(true);
  const [transaction, setTransaction] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [broadcasterOptions, setBroadcasterOptions] = useState([]);
  const balance = useSelector((state) => state.user.user.balance);

  const COUNT_PAGE = useMemo(() => (height < 920 ? 4 : 5), [height]);

  const selectRef = useRef({
    type: undefined,
    broadcaster_id: undefined,
    service: undefined,
    to: undefined,
    from: undefined,
  });

  const selects = [
    { id: "type", label: "Transaction type", options: serviceOptions },
    { id: "broadcaster_id", label: "Select a broadcaster", options: broadcasterOptions },
    // { id: "type", label: "Select a service", options: serviceOptions },
  ];

  const getTransactionServer = async () => {
    selectRef.current.from = startDate ? startDate + " 00:00:00" : undefined;
    selectRef.current.to = endDate ? endDate + " 23:59:00" : undefined;

    if (typeof selectRef.current.type === "string") {
      if (selectRef.current.type) {
        selectRef.current.type = [selectRef.current.type];
      }
    }

    const filter = selectRef.current;
    const paging = { length: COUNT_PAGE, page: currentPage, order: ["created DESC"] };

    setLoader(true);
    await API_PROFILE.transaction
      .list({ filter, paging })
      .then((e) => {
        const data = e.data;
        setTotal(data.filtered);
        const broadcasters = data.broadcasters.map((el) => ({
          label: el.nickname,
          value: el.id,
        }));
        broadcasters.unshift({ label: t("All broadcaster"), value: undefined });
        setBroadcasterOptions((prev) => removeDuplicates([...prev, ...broadcasters]));
        setTransaction(data.payments);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoader(false));

    ////// emulator Server

    if (TEST_MODE) {
      setTotal(dataServer.total);
      setTransaction(dataServer.payments.splice(0, COUNT_PAGE));
    }
  };

  useEffect(() => {
    getTransactionServer();
  }, [currentPage, COUNT_PAGE, balance]);

  const onSearchClick = async () => {
    await getTransactionServer();
  };

  return (
    <div className="statistic">
      <div className="statistic__filter">
        <div className="statistic__filter__row statistic__filter__row__two">
          {selects.map((select) => (
            <div key={select.id} className="select-container">
              <CusSelect
                label={select.label}
                placeholder={select.label}
                options={select.options}
                onChange={(e) => (selectRef.current[select.id] = e)}
                defaultValue={select.options[0]?.value}
              />
            </div>
          ))}
        </div>
        <div className="statistic__filter__row">
          <CusDatePicker setDate={setStartDate} label={"Start date"} />
          <CusDatePicker setDate={setEndDate} label={"Until date"} />
          <CusButton onClick={onSearchClick} text={"Search"} color={"red"} isPromiseLoader />
        </div>
        <div className="statistic__time">
          <IconTimeCircle />
          <h4 className="statistic__time__title">{t("Statistics")}</h4>

          <ShowContainer condition={startDate && endDate}>
            <div className="statistic__time__date">
              <span>{startDate}</span> - <span>{endDate}</span>
            </div>
          </ShowContainer>
        </div>
      </div>

      <div className={`statistic__content ${loader ? "loader" : ""}`}>
        {loader ? (
          <Spinner />
        ) : transaction.length ? (
          <StatisticTable
            transactions={transaction}
            countPage={Math.ceil(total / COUNT_PAGE)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <StatisticEmpty />
        )}
      </div>
    </div>
  );
}
