import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import CloseModal from "../mui/CloseModal";
import CusSelect from "../mui/CusSelect";
import CusButton from "../mui/CusButton";
import { useTranslation } from "react-i18next";
import IconCameraOff from "../../images/jsIcons/IconCameraOff2";
import Spinner from "../mui/Spinner";
import { getOptionsVideo } from "../../utils/utils";
import { useSelector } from "react-redux";
import { errorToast } from "../mui/Toaster";
import { useLocalStorage } from "usehooks-ts";
import useJanus from "../../hooks/useJanus";
// import { ENUM_CHAT_MODE, setMyStream } from "../../store/chatSlice";
import { resolutionOptionAll, TEST_MODE } from "../common/consts";
import { ENUM_LOCALSTORAGE } from "../common/enums";
import { ENUM_CHAT_MODE } from "../../store/chatSlice";
import ShowContainer from "../mui/ShowContainer";
import "../../style/chat/camera-setting.scss";

export const ENUM_CAMERA_STATE = { off: false };
export const ENUM_MICRO_STATE = { off: false };

export default function CameraSetting({
  isOpen,
  closeModal,
  isOfferStream,
  setMyStream,
  onSaveClick,
  buttonText = "Save",
}) {
  const { offerStream } = useJanus({});
  const { t } = useTranslation();

  const [saveResolutions, setSaveResolutions] = useLocalStorage(
    ENUM_LOCALSTORAGE.saveResolutions,
    {}
  );
  const [, setCameraId] = useLocalStorage(ENUM_LOCALSTORAGE.cameraId, undefined);
  const [, setDevices] = useLocalStorage(ENUM_LOCALSTORAGE.devices, {});
  const [camera, setCamera] = useLocalStorage(ENUM_LOCALSTORAGE.localCamera, ENUM_CAMERA_STATE.off);
  const [micro, setMicro] = useLocalStorage(ENUM_LOCALSTORAGE.localMicro, ENUM_MICRO_STATE.off);
  const [resolution, setResolution] = useLocalStorage(ENUM_LOCALSTORAGE.localResolution, undefined);

  const isMobile = useSelector((state) => state.deviceSize.isMobile);
  const userId = useSelector((state) => state.user.user.id);
  const chatActiveMode = useSelector((state) => state.chat.chatActiveMode);

  // options
  const [cameraOptions, setCameraOptions] = useState([]);
  const [resolutions, setResolutions] = useState([]);
  const [microphones, setMicrophones] = useState([]);

  // other
  const [loader, setLoader] = useState(true);
  const [loadingResolutions, setLoadingResolution] = useState(true);
  const [loadingCamera, setLoadingCamera] = useState(true);
  // refs
  const videoRef = useRef(null);
  const videoRef2 = useRef(null);
  const mediaStreamRef = useRef();
  const keepStreamRef = useRef(false);

  const isDisabled = isOfferStream && chatActiveMode !== ENUM_CHAT_MODE.exclusive;

  const getResolutionList = (deviceId) => {
    async function checkCameraResolutionSupport(deviceId, resolutionList) {
      try {
        const supportedResolutions = [];

        // Для каждого разрешения пробуем получить видеопоток
        for (let res of resolutionList) {
          const [width, height] = res.value.split("x").map(Number);
          try {
            await navigator.mediaDevices.getUserMedia({
              video: {
                deviceId,
                width: { exact: width },
                height: { exact: height },
              },
            });
            supportedResolutions.push(res);
          } catch (err) {
            // Если разрешение не поддерживается, просто пропускаем
          }
        }

        return supportedResolutions;
      } catch (error) {
        console.error("Ошибка при получении видеопотока:", error);
        return [];
      }
    }

    return new Promise((rs, rj) => {
      // rs(resolutionOptionAll);
      // return;
      checkCameraResolutionSupport(deviceId, resolutionOptionAll).then(rs).catch(rj);
    });
  };

  const getResolution = (videoId) => {
    return new Promise((rs, rj) => {
      const getList = (list) => {
        if (saveResolutions[videoId]) rs(saveResolutions[videoId]);
        rs(list);
      };

      getResolutionList(videoId).then(getList).catch(rj);
    });
  };

  const initResolution = async (videoOptions) => {
    const newResolution = {};
    for (let i = 0; i < videoOptions.length; i++) {
      const videoId = videoOptions[i].value;

      if (saveResolutions[videoId]) continue;

      const list = await getResolution(videoId);
      newResolution[videoId] = list;
    }

    setSaveResolutions(newResolution);
    setLoadingResolution(false);
  };

  const closeConnect = () => {
    if (keepStreamRef.current) return;
    mediaStreamRef.current?.getTracks?.()?.forEach((t) => t?.stop?.());
  };

  const handleCLose = () => {
    closeConnect();
    closeModal();
  };

  const onButtonCLick = () => {
    keepStreamRef.current = true;

    if (typeof onSaveClick === "function") {
      onSaveClick(mediaStreamRef.current);
    }

    const fn = async () => {
      const newDevices = { videoId: camera, microId: micro, resolution: resolution };
      setCameraId(camera);
      setDevices(newDevices);
      handleCLose();

      if (isOfferStream) {
        if (!TEST_MODE) offerStream(newDevices, !!camera, !!micro);
        setMyStream?.(mediaStreamRef.current);
      }
    };

    setTimeout(() => fn(), 100);
  };

  const onDisableClick = () => {
    const messageText = userId
      ? "Broadcast your camera only in a Private Exclusive chat"
      : "Camera broadcasting is available only to authorized users";

    errorToast(t(messageText));
  };

  const getFillOptions = async () => {
    const { videoOptions, audioOptions } = await getOptionsVideo();

    setCameraOptions([...videoOptions, { label: t("Do not use"), value: ENUM_CAMERA_STATE.off }]);
    setMicrophones([...audioOptions, { label: t("Do not use"), value: ENUM_MICRO_STATE.off }]);
  };

  const getVideo = () => {
    const video = videoRef.current;

    const constraints = {
      audio: { deviceId: micro ? { exact: micro } : undefined },
      video: {
        deviceId: camera ? { exact: camera } : undefined,
        width: resolution ? { exact: +resolution.split("x")?.[0] } : undefined,
        height: resolution ? { exact: +resolution.split("x")?.[1] } : undefined,
      },
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (mediaStream) {
        mediaStreamRef.current = mediaStream;

        video.srcObject = mediaStream;
        videoRef2.current.srcObject = mediaStream;
        video.onloadedmetadata = () => video.play();
        videoRef2.current.onloadedmetadata = () => videoRef2.current.play();
      })
      .catch(function (err) {
        console.log(err);
        // setCamera(ENUM_CAMERA_STATE.off);
      })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    if (!isOpen) return;
    getResolution(camera)
      .then((list) => {
        setResolutions(list);
        getFillOptions();
        setLoadingCamera(false);
      })
      .catch((e) => console.log(e));
  }, [camera, isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    if (!cameraOptions.length) return;
    initResolution(cameraOptions);
  }, [cameraOptions, isOpen]);

  useEffect(() => {
    setLoader(true);

    if (isOpen && (camera !== ENUM_CAMERA_STATE.off || micro !== ENUM_MICRO_STATE.off)) {
      closeConnect();
      setTimeout(getVideo, 100);
    } else {
      setLoader(false);
    }
  }, [isOpen, camera, micro, resolution]);

  return (
    <Modal
      ariaHideApp={false}
      className={`camera-settings-modal ${t("currentLang")}`}
      isOpen={isOpen}
      onRequestClose={handleCLose}
    >
      <CloseModal close={handleCLose} />

      <h1 className="camera-settings__title">{t("Camera settings")}</h1>
      <div className="camera-settings__user">
        {camera !== ENUM_CAMERA_STATE.off ? (
          <>
            <video
              playsInline
              muted
              ref={videoRef2}
              autoPlay
              className="camera-settings__video__bg"
            />
            <video
              muted
              playsInline
              ref={videoRef}
              autoPlay
              className="camera-settings__video"
              width={isMobile ? "100%" : 520}
            />
          </>
        ) : (
          <div className="camera-settings__no-camera">
            <IconCameraOff />
            <p>{t("No video from camera")}</p>
          </div>
        )}
      </div>
      <CusSelect
        label={"Camera"}
        options={cameraOptions}
        valueProps={camera}
        onChange={setCamera}
        isLoader={loadingCamera}
      />
      <CusSelect
        label={"Camera resolution"}
        options={resolutions}
        valueProps={resolution}
        onChange={setResolution}
        isLoader={loadingResolutions}
      />
      <CusSelect
        label={"Microphone"}
        valueProps={micro}
        options={microphones}
        onChange={setMicro}
      />
      <div className="button-container" onClick={isDisabled ? onDisableClick : undefined}>
        <CusButton
          className={isDisabled ? "disabled" : ""}
          text={buttonText}
          onClick={!isDisabled ? onButtonCLick : undefined}
          color="red"
          disabled={camera === false && micro === false}
        />
      </div>

      <ShowContainer condition={!!loader}>
        <div className="camera-settings__loader">
          <Spinner />
        </div>
      </ShowContainer>
    </Modal>
  );
}
