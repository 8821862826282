import React, { useEffect, useMemo, useRef, useState } from "react";
import { TEST_MODE } from "../../common/consts";
import { testAreaCountries } from "../../../testConst";
import { API_INFO } from "../../../api/info";
import { useTranslation } from "react-i18next";
import IconArrow from "../../../images/jsIcons/IconArrow";
import { useEventListener } from "usehooks-ts";
import "./select-countries.scss";

export default function SelectCountries({ className, label, defaultValue, placeholder, onChange }) {
  const { t } = useTranslation();

  const [areasAll, setAreasAll] = useState([]);
  const [areas, setAreas] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [searchValue, setSearchValue] = useState(defaultValue);

  const searchRef = useRef();

  const labelEl = useMemo(() => areas.find((el) => el[value])?.[value]?.name, [areas, value]);
  const isSelected = (el) => (el === value ? "selected" : "");

  const prevent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const mapKeyNotCodeTitle = (el) =>
    Object.keys(el).filter((el) => el !== "code" && el !== "title");

  const onSearchChange = (value) => {
    setSearchValue(value);

    const tempAreas = [];

    for (let i = 0; i < areasAll.length; i++) {
      const region = areasAll[i];
      const tempRegion = {};
      const regionKey = mapKeyNotCodeTitle(region);

      for (let i = 0; i < regionKey.length; i++) {
        const key = regionKey[i];
        const name = region[key].name.toLowerCase();
        const curValue = value.toLowerCase();
        if (name.includes(curValue)) tempRegion[key] = region[key];
      }

      if (Object.keys(tempRegion).length) {
        tempRegion.title = region.title;
        tempRegion.code = region.code;
      }

      tempAreas.push(tempRegion);
    }
    setAreas(tempAreas);
  };

  useEventListener("click", () => setOpen(false));

  useEffect(() => {
    API_INFO.listAreas().then((e) => {
      const areas = Object.values(e.data.areas);
      setAreas(areas);
      setAreasAll(areas);
    });

    if (TEST_MODE) setAreas(testAreaCountries);
    if (TEST_MODE) setAreasAll(testAreaCountries);
  }, []);

  useEffect(() => {
    if (!value) setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (open) searchRef.current?.focus?.();
  }, [open]);

  return (
    <div className="cus-select-container">
      {label && <label>{t(label)}</label>}

      <div className={`cus-select ${className} ${!areas.length ? "disabled" : ""}`}>
        <button
          className={`cus-select__container__title ${open && areas.length ? "open" : ""}`}
          onClick={(e) => {
            prevent(e);
            setOpen((prev) => !prev);
          }}
        >
          {open ? (
            <input
              className="cus-select__input-text"
              type="text"
              placeholder={placeholder}
              ref={searchRef}
              onChange={(e) => onSearchChange(e.target.value)}
              value={searchValue}
              onClick={(e) => {
                setOpen(true);
                prevent(e);
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") prevent(e);

                if (e.code === "Space") {
                  prevent(e);
                  onSearchChange(searchValue + " ");
                }
              }}
            />
          ) : (
            <span className="cus-select__value">{t(labelEl || placeholder)}</span>
          )}

          <IconArrow className={`${open && areas.length ? "rotate180" : "rotate0"} arrow`} />
        </button>
        {open && !!areas.length && (
          <div className="cus-select__options">
            {areas.map(
              (region, indx) =>
                region.title && (
                  <ul key={indx} className="cus-select__options__region">
                    <span className="region__title">{region.title}</span>
                    {mapKeyNotCodeTitle(region).map((code) => (
                      <li
                        key={code}
                        className={`cus-select__options__li ${isSelected(region[code].code)}`}
                        onClick={() => {
                          const value = region[code].code;
                          setValue(value);
                          setOpen(false);
                          setSearchValue(region[code].name);
                          onChange && onChange(value);
                        }}
                      >
                        <span className="cus-select__options__li__label">
                          {t(region[code].name)}
                        </span>
                      </li>
                    ))}
                  </ul>
                )
            )}
          </div>
        )}
      </div>
    </div>
  );
}
