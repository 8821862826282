import Modal from "react-modal";
import { Segmented } from "antd";
import React, { useEffect, useState } from "react";
import IconProfile from "../../images/jsIcons/IconProfile";
import IconSetting from "../../images/jsIcons/IconSetting";
import IconStatistic from "../../images/jsIcons/IconStatistic";
import IconDerails from "../../images/jsIcons/IconDerails";
import ContentGeneral from "../user/ContentGeneral";
import ContentStatistic from "./ContentStatistic";
import ContentSetting from "./ContentSetting";
import NewAvatar from "./NewAvatar";
import ModalHeader from "../mui/ModalHeader";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "../mui/error_boundary/ErrorBoundary";

export default function ProfileCommonModal({ isOpen, setOpen, type }) {
  const [chapter, setChapter] = useState(type);
  const [changePhoto, setChangePhoto] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setChapter(type);
  }, [type]);

  useEffect(() => {
    setChangePhoto(false);
  }, [isOpen]);

  const createTemplateSegment = (icon, title = "") => (
    <div className="header__segment__label">
      {icon}
      <span>{t(title)}</span>
    </div>
  );

  const segmentOption = [
    {
      label: createTemplateSegment(<IconProfile />, "General"),
      value: "general",
    },
    {
      label: createTemplateSegment(<IconStatistic />, "Statistic"),
      value: "statistic",
    },
    {
      label: createTemplateSegment(<IconSetting />, "Personal settings"),
      value: "settings",
    },
  ];

  const closeCurrentModal = () => setOpen(false);

  const elementById = {
    general: (
      <ContentGeneral
        setChangePhoto={setChangePhoto}
        closeModal={closeCurrentModal}
        setChapter={setChapter}
      />
    ),
    statistic: <ContentStatistic />,
    settings: <ContentSetting />,
  };

  const titleById = {
    general: "Your account details",
    statistic: "Your account statistics",
    settings: "Personal settings for your account",
  };

  return (
    <Modal
      ariaHideApp={false}
      className={"profile-modal"}
      isOpen={isOpen}
      onRequestClose={closeCurrentModal}
    >
      {changePhoto ? (
        <ErrorBoundary>
          <NewAvatar setChangePhoto={setChangePhoto} isOpen={isOpen} />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary>
          <div className="profile__content">
            <ModalHeader
              closeModal={closeCurrentModal}
              icon={<IconDerails />}
              title={t(titleById[chapter])}
            />
            <Segmented
              value={chapter}
              onChange={(e) => setChapter(e)}
              options={segmentOption}
              rootClassName="header__segment__root"
              classID="header__segment__id"
              className="header__segment__some"
            />
          </div>
          {elementById[chapter]}
        </ErrorBoundary>
      )}
    </Modal>
  );
}
