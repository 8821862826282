import React, { useCallback } from "react";
import IconArrow from "../../images/jsIcons/IconArrow";
import ShowContainer from "./ShowContainer";
import { useSelector } from "react-redux";

//currentPage - текущая страница
//setCurrentPage - изменение текущий страницы
//MAX_PAGE - число показывающая количество ячеек пагинации

export default function Pagination({ currentPage, setCurrentPage, MAX_PAGE }) {
  const isMobile = useSelector((state) => state.deviceSize.isMobile);

  const drownPages = useCallback(
    (count, currentPage) => {
      const pages = [];
      const countPages = 8;
      const mobileCuntPages = 5;
      const countLastPageDesktop = countPages - (count - currentPage + 1);
      const countLastPageMobile = mobileCuntPages - (count - currentPage + 1);

      const countLastPage = isMobile ? countLastPageMobile : countLastPageDesktop;

      const pushDiv = (i, newPage = i) =>
        pages.push(
          <div
            className={`page ${currentPage === i ? "current" : ""}`}
            onClick={() => setCurrentPage(newPage)}
          >
            {i}
          </div>
        );

      if (isMobile) {
        for (let i = 2; i < count; i++) {
          if ([1, 2, 3].includes(currentPage)) {
            if (i < mobileCuntPages - 1) pushDiv(i);
            if (i === mobileCuntPages) pushDiv("...", 4);
          } else if (currentPage + mobileCuntPages - 3 >= count) {
            if (currentPage - countLastPage === i + 1) pushDiv("...", i + 2);
            if (currentPage - countLastPage + 2 <= i) pushDiv(i);
            continue;
          } else {
            if (i === currentPage - 2) pushDiv("...", currentPage - 1);
            if (i >= currentPage - 0 && i < currentPage + mobileCuntPages - 4) pushDiv(i);
            if (count - 3 === i) pushDiv("...", currentPage + 1);
          }
          if (count - 1 < i) pushDiv(i);
        }
      } else {
        for (let i = 2; i < count; i++) {
          if ([1, 2, 3].includes(currentPage)) {
            if (i < countPages) pushDiv(i);
            if (count !== 9 && i === countPages) pushDiv("...", countPages);
          } else if (currentPage + countPages - 2 >= count) {
            if (currentPage - countLastPage === i + 1)
              pushDiv("...", currentPage - countLastPage - 1);
            if (currentPage - countLastPage <= i) pushDiv(i);
            continue;
          } else {
            if (i === currentPage - 2) pushDiv("...", currentPage - 2);
            if (i >= currentPage - 1 && i < currentPage + countPages - 4) pushDiv(i);
            if (count - 3 === i) pushDiv("...", count - 2);
          }
          if (count > countPages && count - 2 < i) pushDiv(i);
        }
      }

      return pages.map((el, indx) => <div key={indx}>{el}</div>);
    },
    [isMobile, setCurrentPage]
  );

  const btnPrev = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  const btnNext = () => setCurrentPage(currentPage < MAX_PAGE ? currentPage + 1 : MAX_PAGE);

  const isFirst = currentPage === 1;
  const isLast = currentPage === MAX_PAGE;

  return (
    <div className="pagination">
      <div className="pagination__content">
        <ShowContainer condition={MAX_PAGE > 1}>
          <div className="pagination__container">
            <button onClick={btnPrev} className="pagination__prev" disabled={isFirst}>
              <IconArrow className={"rotate90"} />
            </button>
            <div className="pagination__pages">
              <div
                onClick={() => setCurrentPage(1)}
                className={`page page__first ${isFirst ? "current" : ""} `}
              >
                1
              </div>

              {drownPages(MAX_PAGE, currentPage)}

              <div
                onClick={() => setCurrentPage(MAX_PAGE)}
                className={`page page__last ${isLast ? "current" : ""}`}
              >
                {MAX_PAGE}
              </div>
            </div>
            <button onClick={btnNext} className="pagination__next" disabled={isLast}>
              <IconArrow className="rotate270" />
            </button>
          </div>
        </ShowContainer>
      </div>
    </div>
  );
}
