import { useDispatch } from "react-redux";
import { ENUM_AVATAR_PLUG } from "../components/common/consts";
import { useAppSelector } from "../store/index.ts";
import { setSomeoneAvatar } from "../store/userSlice";
import { getUrlAvatar, toDataURL } from "../utils/utils";
import profileDefault from "../images/mui/profile.svg";
import genderDefault from "../images/template/no-img.svg";
import JanusState from "../controls/janus/site/JanusState.js";

const DELAY_MIN_UPDATE = 3 * 1000;

const plugToId = {
  [ENUM_AVATAR_PLUG.gender]: genderDefault,
  [ENUM_AVATAR_PLUG.rabbit]: profileDefault,
};

export default function useAvatar({ id, url, plug = ENUM_AVATAR_PLUG.rabbit }) {
  const dispatch = useDispatch();
  const someoneAvatar = useAppSelector((state) => state.user?.someoneAvatar?.[id]);
  const userId = useAppSelector((state) => state.user.user.id);
  const myAvatar = useAppSelector((state) => state.user?.user?.avatar);

  const getAvatar = () => {
    if (!someoneAvatar) return plugToId[plug];
    return someoneAvatar;
  };

  const makeUpdate = () => {
    console.log("makeUpdate");

    if (!id) return;
    if (url) {
      toDataURL(url, (avatar) => dispatch(setSomeoneAvatar({ id, avatar })));
      return;
    }

    if (userId === id && myAvatar) {
      toDataURL(myAvatar, (avatar) => dispatch(setSomeoneAvatar({ id, avatar })));
      return;
    }

    const urlAvatar = getUrlAvatar(id);

    toDataURL(urlAvatar, (avatar) => dispatch(setSomeoneAvatar({ id, avatar })));
    return;
  };

  const updateAvatar = () => {
    clearTimeout(JanusState.avatarUpdateId);
    JanusState.avatarUpdateId = setTimeout(() => makeUpdate(), DELAY_MIN_UPDATE);
  };

  const checkAvatar = () => {
    if (someoneAvatar) return;
    updateAvatar();
  };

  return { avatar: getAvatar(), updateAvatar, checkAvatar };
}
