import React from "react";

const TEXT_EMPTY = "Заполните это поле";
const NOT_VALID_EMAIL = "Введите валидный адрес почты";
const TEXT_MIN_LENGTH = "Минимальная длинна поля ";

export default function CusForm({ children, className, onSubmit, onError }) {
  const onFormSubmit = (e) => {
    e.preventDefault();
    let isSubmit = true;
    const inputFields = {};

    const dispatchCuEvent = (message, target, eventName = "inputError") => {
      const event = new CustomEvent(eventName, { detail: { message, target } });
      window.dispatchEvent(event);
      isSubmit = false;
    };

    const getInputs = (selector) => e.target.querySelectorAll(selector);

    // FIELD input
    getInputs("input").forEach((input) => {
      const name = input.getAttribute("name");
      inputFields[name] = input.value;
    });

    // CHECK_VALID_EMAIL
    getInputs("input[data-type]").forEach((input) => {
      if (input.getAttribute("data-type") === "email") {
        const value = input.value;
        if ((value.indexOf("@") < 0 || value.indexOf(".") < 0) && value !== "") {
          dispatchCuEvent(NOT_VALID_EMAIL, input);
        }
      }
    });

    // CHECK_MIN_LENGTH
    getInputs("input[data-minlength]").forEach((input) => {
      const minLength = input.getAttribute("data-minlength");
      if (input.value.length < minLength && input.value !== "") {
        dispatchCuEvent(TEXT_MIN_LENGTH + minLength, input);
      }
    });

    // CHECK_TEXT_EMPTY
    getInputs("input[data-required]").forEach((input) => {
      if (input.value === "") dispatchCuEvent(TEXT_EMPTY, input);
    });

    /**
     * @textarea
     */

    // FIELD textarea
    getInputs("textarea").forEach((input) => {
      const name = input.getAttribute("name");
      inputFields[name] = input.value;
    });

    // CHECK_TEXT_EMPTY
    getInputs("textarea[data-required]").forEach((input) => {
      if (input.value === "") dispatchCuEvent(TEXT_EMPTY, input);
    });

    /**
     * @fieldset
     */

    getInputs("fieldset[data-required]").forEach((fieldset) => {
      const radios = fieldset.querySelectorAll("input[type=radio]");
      for (let i = 0; i < radios.length; i++) {
        const radio = radios[i];
        if (radio.checked) return;
      }
      dispatchCuEvent(TEXT_EMPTY, fieldset, "fieldsetError");
    });

    if (isSubmit) {
      onSubmit(e, inputFields);
    } else {
      onError(e, inputFields);
    }
  };

  return (
    <form className={className} onSubmit={onFormSubmit}>
      {children}
    </form>
  );
}
