import React, { useEffect } from "react";
import useAvatar from "../../hooks/useAvatar";

const ImgAvatar = ({ className = "avatar__btn", avatarProps, autoWidthImg, plug, id }) => {
  const { avatar, checkAvatar } = useAvatar({ id, url: avatarProps, plug });

  useEffect(() => {
    checkAvatar();
  }, [id, avatarProps]);

  return (
    <div className={`${className}  avatar-rect`}>
      <div className="avatar-rect__img-container">
        <img width={autoWidthImg ? "auto" : "100%"} src={avatar} alt={"Avatar"} />
      </div>
    </div>
  );
};

export default React.memo(ImgAvatar);
