import React, { useEffect, useMemo, useState } from "react";
import IconArrow from "../../images/jsIcons/IconArrow";
import { useEventListener } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import ShowContainer from "./ShowContainer";
import Spinner from "./Spinner";

/**
 * Кастомный селект
 *
 * @param {Object} props - Свойства компонента
 * @param {Array<{icon?: React.ReactNode, label: string, value: string, onClick?: () => void}>} props.options - Опции селекта
 * @param {Function} [props.onChange] - Колбэк при изменении выбора
 * @param {string|React.ReactNode} [props.title=""] - Заголовок селекта
 * @param {string} [props.label] - Лейбл для селекта
 * @param {string} [props.defaultValue=""] - Значение по умолчанию
 * @param {string} [props.placeholder="not chosen"] - Текст-заглушка, если ничего не выбрано
 * @param {boolean} [props.staticHeader=false] - Фиксированный заголовок
 * @param {string} [props.className=""] - Дополнительные CSS-классы
 * @param {string} [props.valueProps] - Внешний пропс для значения
 * @param {boolean} [props.input=false] - Включить ввод текста в селекте
 * @param {boolean} [props.isLoader=false] - loader селекта
 */
export default function CusSelect({
  options: optionsProps,
  onChange,
  title = "",
  label,
  defaultValue = "",
  placeholder = "Не выбрано",
  staticHeader = false,
  className = "",
  valueProps,
  input,
  isLoader = false,
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [options, setOptions] = useState(optionsProps);
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  const labelEl = useMemo(() => options.find((el) => el.value === value)?.label, [options, value]);
  const isSelected = (el) => (el.value === value ? "selected" : "");

  useEventListener("click", () => setOpen(false));

  useEffect(() => {
    if (!value) setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setOptions(optionsProps);
  }, [optionsProps]);

  useEffect(() => {
    if (!open) setInputValue(labelEl || "");
  }, [open, labelEl]);

  useEffect(() => setValue(valueProps), [valueProps]);

  const sortedByName = (search, a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();
    const indexA = labelA.indexOf(search.toLowerCase());
    const indexB = labelB.indexOf(search.toLowerCase());

    if (indexA !== -1 && indexB !== -1) return indexA - indexB;
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    return a.originalIndex - b.originalIndex;
  };

  const onInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    const newOption = optionsProps.sort((a, b) => sortedByName(value, a, b));
    setOptions(newOption);
  };

  const onBtnTitleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen((prev) => !prev);
  };

  const onInputClick = (e) => {
    setOpen(true);
    e.preventDefault();
    e.stopPropagation();
  };

  const onInputKeyDown = (e) => {
    if (e.code === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.code === "Space") {
      e.preventDefault();
      e.stopPropagation();
      onInputChange({ target: { value: inputValue + " " } });
    }
  };

  const CusSelectValue = () => (
    <span className="cus-select__value">{t(labelEl || placeholder)}</span>
  );

  return (
    <div className="cus-select-container">
      {label && <label>{t(label)}</label>}

      <div className={`cus-select ${className} ${!options.length ? "disabled" : ""}`}>
        <button
          className={`cus-select__container__title ${open && options.length ? "open" : ""}`}
          onClick={!isLoader ? onBtnTitleClick : undefined}
        >
          {isLoader && <Spinner className="select__loader" />}

          <ShowContainer condition={!isLoader}>
            {title && <span className="cus-select__title">{title}</span>}

            {input ? (
              <input
                className="cus-select__input-text"
                type="text"
                placeholder={placeholder}
                onChange={onInputChange}
                value={inputValue}
                onClick={onInputClick}
                onKeyDown={onInputKeyDown}
              />
            ) : (
              staticHeader || <CusSelectValue />
            )}

            <IconArrow className={`${open && options.length ? "rotate180" : "rotate0"} arrow`} />
          </ShowContainer>
        </button>

        <ShowContainer condition={open && !!options.length}>
          <div className="cus-select__options">
            <ul>
              {options.map((el, indx) => (
                <li
                  key={indx}
                  className={`cus-select__options__li ${isSelected(el)}`}
                  onClick={() => {
                    const value = options?.[indx]?.value;
                    const label = options?.[indx]?.label;
                    if (!staticHeader) setValue(value);
                    setOpen(false);
                    onChange && onChange(value);
                    el?.onClick?.();
                    setInputValue(label);
                  }}
                >
                  {!!el.icon && <div className="cus-select__options__li__icon">{el.icon}</div>}
                  <span className="cus-select__options__li__label">{t(el.label)}</span>
                </li>
              ))}
            </ul>
          </div>
        </ShowContainer>
      </div>
    </div>
  );
}
