import { useSelector } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import commonSlice from "./commonSlice";
import siteInfoSlice from "./siteInfoSlice";
import chatSlice from "./chatSlice";
import userSlice from "./userSlice";
import homeSlice from "./homeSlice";
import deviceSizeSlice from "./deviceSizeSlice";

const rootReduces = combineReducers({
  common: commonSlice,
  user: userSlice,
  siteInfo: siteInfoSlice,
  chat: chatSlice,
  home: homeSlice,
  deviceSize: deviceSizeSlice,
});

export const store = configureStore({ reducer: rootReduces });

type RootState = ReturnType<typeof rootReduces>;

export const useAppSelector = useSelector.withTypes<RootState>();
