export const ENUM_LOCALSTORAGE = {
  autostart: "autostart",
  cameraId: "cameraId",
  devices: "devices",
  localCamera: "localCamera",
  localMicro: "localMicro",
  localResolution: "localResolution",
  lastAmount: "lastAmount",
  confirmAge: "confirmAge",
  haveAccount: "haveAccount",
  cameraPerm: "cameraPermission",
  typeModel: "typeModel",
  typeSort: "typeSort",
  shown: "shown",
  filter: "filter",
  isRestore: "isRestore",
  saveResolutions: "saveResolutions",
};

export const ENUM_ELEMENT_ID = {
  chatBroadcaster: "chat-broadcaster",
  chatContent: "chat-content",
  zoomistContainer: "zoomist-container",
  zoomistWrapper: "zoomist-wrapper",
  fullScreen: "full-screen",
};

export const ENUM_ELEMENT_NAME = {
  streamProfile: "stream-profile",
  streamMain: "stream-main",
};
