import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";

export default function CusButton({
  img,
  icon,
  id,
  text,
  className = "",
  onClick,
  loading,
  disabled,
  type = "button",
  color = "default",
  fullWidth,
  children,
  endIcon,
  promiseLoader: promiseLoaderProps,
  isPromiseLoader,
  ref,
}) {
  const { t } = useTranslation();

  const isLoading = loading ? "loading" : "";
  const [promiseLoader, setPromiseLoader] = useState(false);

  // const BtnOrLink = (props) => {
  //   props.href ? (
  //     <Link {...props}>{props.children}</Link>
  //   ) : (
  //     <button {...props}>{props.children}</button>
  //   );
  // };

  useEffect(() => {
    setPromiseLoader(promiseLoaderProps);
  }, [promiseLoaderProps]);

  return (
    <button
      ref={ref}
      id={id}
      type={type}
      className={`custom-button ${isLoading} ${className} custom-button__${color} ${
        fullWidth ? "full-width" : ""
      }`}
      onClick={async () => {
        setPromiseLoader(isPromiseLoader);
        await onClick?.();
        setPromiseLoader(false);
      }}
      disabled={disabled || loading}
    >
      {!!promiseLoader && !loading && (
        <div className="custom-button-spinner">
          <Spinner />
        </div>
      )}

      {children ? (
        loading ? (
          <Spinner />
        ) : (
          children
        )
      ) : (
        <>
          {!!img && <img src={img} alt="" />}
          {!!icon && icon}
          {!!text && <span>{t(text)}</span>}
          {!!endIcon && endIcon}
          {loading && <Spinner />}
        </>
      )}
    </button>
  );
}
