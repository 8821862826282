/* eslint-disable no-dupe-class-members */

import { ENUM_STATUS_WINDOW, setStatusWindow } from "../../../store/chatSlice";
import { ENUM_MODALS_VALUE, setModals } from "../../../store/commonSlice";
import JanusState from "./JanusState";

const ATTEMPTS_RECONNECT = 3;

export default class JanusDisconnect {
  static #instance = null;
  // - ид реконнекта
  static reconnectId = 0;
  // - флаг есть ли возможность реконнекта
  static reconnectEnabled = true;

  // - флаг происходит ли сейчас реконнект
  #isReconnect;

  //  - количество попыток реконнекта
  badAttempts;

  // - функция подключения к янусу
  initJanus;

  // - функция для изменения стора
  dispatch;

  constructor({ initJanus, dispatch, chatId, roomId }) {
    // если такой класс уже был создан и попытка создать с такими же параметрами возвращаем старый
    if (JanusDisconnect.#instance) {
      console.log("already instance");
      return JanusDisconnect.#instance;
    }

    this.reconnectId = 0;
    this.badAttempts = 0;
    this.initJanus = initJanus;
    this.#isReconnect = false;
    this.roomId = roomId;
    this.chatId = chatId;
    this.dispatch = dispatch;
    JanusDisconnect.#instance = this;
  }

  get reconnectId() {
    return JanusDisconnect.reconnectId;
  }
  /**
   * @type {(setter)} JanusDisconnect.reconnectId: number
   * @param {number} id
   */
  set reconnectId(id) {
    JanusDisconnect.reconnectId = id;
  }

  static clearBadAttempts() {
    JanusDisconnect.#instance.badAttempts = 0;
  }

  // getRandom - получение рандомного времени
  #getRandom = (a = 3, b = 0) => Math.max(2, Math.random() * a) + b;

  #debugLog(mes, st) {
    console.log(st, mes);
  }

  get isReconnect() {
    return this.#isReconnect;
  }

  get #getRandomByAttempts() {
    if (this.badAttempts === 0) return Math.random();
    if (this.badAttempts < 3) return this.#getRandom(3, 1.1);
    if (this.badAttempts < 5) return this.#getRandom(7, 3);
    if (this.badAttempts < 15) return this.#getRandom(10, 5);
    return this.#getRandom(20, 10);
  }

  //reconnect - функция переподключения
  async reconnect() {
    if (JanusState.isLeave) return this.#debugLog("leave", 81);
    if (!JanusDisconnect.reconnectEnabled) return this.#debugLog("!reconnectEnabled", 82);
    if (this.#isReconnect) return this.#debugLog("this.isReconnect", 83);

    console.log("status loading");
    this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.loading));
    this.#isReconnect = true;
    clearTimeout(this.reconnectId);
    this.reconnectId = 0;
    console.log("reconnect");
    const getRandom = this.#getRandom;

    try {
      if (!this.initJanus) {
        this.#debugLog("no init Janus");
        this.#isReconnect = false;
        return;
      }
      this.badAttempts++;
      //  await JanusState.clientChat?.destroy?.();
      const connected = await this.initJanus(this.roomId, this.chatId);

      console.log("info connected", connected);
      if (!connected) {
        console.log("connected bad");
        this.reconnectId = setTimeout(() => this.reconnect(), getRandom(5, 3) * 1000);
      }
      console.log("good connect");
    } catch (e) {
      console.log(e);
      this.reconnectId = setTimeout(() => this.reconnect(), getRandom(7, 4) * 1000);
    }
    this.#isReconnect = false;
  }

  //doReconnect - функция запуска переподключения
  doReconnect(roomId, chatId) {
    if (!JanusDisconnect.reconnectEnabled) return this.#debugLog("!reconnectEnabled", 79);

    if (this.badAttempts > ATTEMPTS_RECONNECT) {
      this.dispatch(setModals({ name: ENUM_MODALS_VALUE.noConnect, value: true }));
    }

    console.log("badAttempts", this.badAttempts);
    this.#isReconnect = false;
    clearTimeout(this.reconnectId);

    this.roomId = roomId;
    this.chatId = chatId;
    const time = this.#getRandomByAttempts * 1000;
    console.log(time);
    this.reconnectId = setTimeout(() => this.reconnect(), time);
  }
}
