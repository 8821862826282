import React, { lazy, Suspense, useEffect } from "react";
import ChoseLang from "../../mui/ChoseLang";
import { useDispatch, useSelector } from "react-redux";
import { setTypeModel } from "../../../store/commonSlice";
import Logo from "../../mui/Logo";
import ShowContainer from "../../mui/ShowContainer";
import HeaderSegment from "./HeaderSegment";

import HeaderFind from "./HeaderFind.jsx";

const HeaderButton = lazy(() => import("../HeaderButton"));

export default function Header({ setOpenLoginModal, headerRef }) {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.user.user?.id);
  const optionsSex = useSelector((state) => state.siteInfo.commonSiteInfo.options?.sex);
  const mobileHeader = useSelector((state) => state.deviceSize.isLsm);

  useEffect(() => {
    const value = optionsSex.find((el) => el.selected)?.value;
    if (value) dispatch(setTypeModel(value));
  }, [optionsSex]);

  return (
    <>
      <header className={`header ${userId ? "authorized" : ""}`} ref={headerRef}>
        <div className={"header__content"}>
          <Logo className="header__logo" />
          <HeaderSegment isTopSegment />

          <ShowContainer condition={!mobileHeader}>
            <div className="header__find">
              <HeaderFind />
            </div>
          </ShowContainer>

          <div className="header__lang">
            <ChoseLang />
          </div>
          <Suspense>
            <HeaderButton setOpenLoginModal={setOpenLoginModal} />
          </Suspense>
        </div>

        <ShowContainer condition={mobileHeader}>
          <HeaderSegment classNameSegment="mobile-segment" />
        </ShowContainer>
      </header>
    </>
  );
}
