import React, { useEffect, useMemo, useRef, useState } from "react";
import CusFind from "../../mui/CusFind";
import { GM_HEADER } from "../../../store/enums";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store/index.ts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setNickName } from "../../../store/commonSlice";

export default function HeaderFind() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cbTimeRef = useRef(null);
  const { t } = useTranslation();

  const width = useAppSelector((state) => state.deviceSize.width);
  const nickname = useAppSelector((state) => state.common.nickname);
  const userId = useAppSelector((state) => state.user.user?.id);

  const [curNickname, setCurNickname] = useState(nickname);

  useEffect(() => {
    setCurNickname(nickname);
  }, [nickname]);

  const goToHome = () => {
    if (nickname) {
      navigate("/");
      setCurNickname("");
    }
  };

  const findPlaceholder = useMemo(
    () => (userId && width < GM_HEADER ? "By nickname" : "Sort a model by nickname"),
    [userId, width]
  );

  const onChangeFind = (e) => {
    const value = e.target.value;
    clearTimeout(cbTimeRef.current);
    setCurNickname(value);

    cbTimeRef.current = setTimeout(() => {
      dispatch(setNickName(value));
      goToHome();
    }, 500);
  };

  return <CusFind value={curNickname} onChange={onChangeFind} placeholder={t(findPlaceholder)} />;
}
