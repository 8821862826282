import React, { lazy, Suspense, useRef, useState } from "react";
import Header from "./Header";
import { useSelector } from "react-redux";
import useCheckBlockHeight from "../../../hooks/useCheckBlockHeight";
import { setHeightHeader } from "../../../store/siteInfoSlice";
import MobileHeader from "./MobileHeader";

const SignUpModal = lazy(() => import("../../common/SignUpModal"));
const HeaderMobileMenu = lazy(() => import("../HeaderMobileMenu"));

export default function HeaderContainer() {
  const mobileHeader = useSelector((state) => state.deviceSize.isMobileHeader);

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const headerRef = useRef(null);

  useCheckBlockHeight(headerRef, setHeightHeader, [mobileHeader]);

  return (
    <>
      {mobileHeader ? (
        <MobileHeader
          setOpenLoginModal={setOpenLoginModal}
          setOpenMobileMenu={setOpenMobileMenu}
          openMobileMenu={openMobileMenu}
          headerRef={headerRef}
        />
      ) : (
        <Header setOpenLoginModal={setOpenLoginModal} headerRef={headerRef} />
      )}

      <Suspense>
        <SignUpModal isOpen={!!openLoginModal} type={openLoginModal} setOpen={setOpenLoginModal} />
        <HeaderMobileMenu isOpen={openMobileMenu} closeModal={() => setOpenMobileMenu(false)} />
      </Suspense>
    </>
  );
}
