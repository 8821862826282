import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useEventListener } from "usehooks-ts";

export default function useCheckBlockHeight(blockRef, setHeight, dp = []) {
  const dispatch = useDispatch();

  const makeHeaderHeight = () => {
    if (!blockRef.current) return;
    const height = blockRef.current.offsetHeight;

    dispatch(setHeight(height));
  };

  useEventListener("resize", () => {
    makeHeaderHeight();
  });

  useEffect(() => {
    makeHeaderHeight();
  }, dp);
}
