import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTypeModel } from "../../../store/commonSlice";
import Logo from "../../mui/Logo";
import HeaderButton from "../HeaderButton";
import HeaderSegment from "./HeaderSegment";
import IconCross from "../../../images/jsIcons/IconCross";
import IconFilters from "../../../images/jsIcons/IconFilters";
import ShowContainer from "../../mui/ShowContainer";

export default function MobileHeader({
  setOpenLoginModal,
  openMobileMenu,
  setOpenMobileMenu,
  headerRef,
}) {
  const user = useSelector((state) => state.user.user?.id);
  const optionsSex = useSelector((state) => state.siteInfo.commonSiteInfo.options?.sex);
  const isLsm = useSelector((state) => state.deviceSize.isLsm);

  const dispatch = useDispatch();

  useEffect(() => {
    const value = optionsSex.find((el) => el.selected)?.value;
    if (value) dispatch(setTypeModel(value));
  }, [optionsSex]);

  return (
    <>
      <header className={`header ${user ? "authorized" : ""}`} ref={headerRef}>
        <div className={"header__content"}>
          <Logo className="header__logo" />
          <ShowContainer condition={!isLsm}>
            <HeaderSegment classNameSegment="mobile-segment" />
          </ShowContainer>

          <HeaderButton setOpenLoginModal={setOpenLoginModal} />
          <button
            title="filters"
            className="button-filters"
            onClick={() => setOpenMobileMenu((prev) => !prev)}
          >
            {openMobileMenu ? <IconCross /> : <IconFilters />}
          </button>
        </div>
        <ShowContainer condition={isLsm}>
          <HeaderSegment classNameSegment="mobile-segment" />
        </ShowContainer>
      </header>
    </>
  );
}
