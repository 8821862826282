import { TYPE_ENUM } from "../../../components/chat/chat/ChatFree";
import { debugLog, ENUM_CUS_EVENT, ENUM_TOAST_TYPE } from "../../../components/common/consts";
import { errorToast, infoToast } from "../../../components/mui/Toaster";
import {
  ENUM_CHAT_MODE,
  ENUM_STATUS_WINDOW,
  modeByStatus,
  seRemMessages,
  setChatActiveMode,
  setLoadingBroadcaster,
  setLovenseEnable,
  setLovenseQueue,
  setLovenseRunning,
  setLovenseTips,
  setLovenseToys,
  setLovenseWaiting,
  setMessagePin,
  setNewMessages,
  setStatusWindow,
} from "../../../store/chatSlice";
import { ENUM_MODALS_VALUE, setModals } from "../../../store/commonSlice";
import { setBalance } from "../../../store/userSlice";
import { dispatchCusEvent } from "../../../utils/utils";
import JanusDisconnect from "./JanusDisconnect";
import JanusState from "./JanusState";

export default class JanusInside {
  dispatch; // функция обновления стора из редакс
  navigate; // хук для навигации по сайту
  isActive; // активен ли поток с януса
  #userId; // id пользователя

  constructor({ dispatch, navigate, destroyCurrent, initJanus, broadcasterId, chatId }) {
    this.dispatch = dispatch;
    this.navigate = navigate;
    this.isActive = true;
    this.destroyCurrent = destroyCurrent;
    this.initJanus = initJanus;
    this.roomId = broadcasterId;
    if (!window.badAttempts) window.badAttempts = 0;
    this.#userId = 0;
    this.chatId = chatId;
    this.disconnect = new JanusDisconnect({ initJanus, chatId, dispatch, roomId: broadcasterId });
    this.isPaidChat = false;
  }

  get userId() {
    return this.#userId || (JanusState.clientChat?.options?.user?.id ?? 0);
  }

  async newUserId(id) {
    this.#userId = id;
  }

  #checkPaidChat() {
    // this.isPaidChat = true;
    const paidList = [
      ENUM_CHAT_MODE.paid,
      ENUM_CHAT_MODE.view,
      ENUM_CHAT_MODE.private,
      ENUM_CHAT_MODE.exclusive,
    ];
    const roomMode = JanusState.roomMode;
    const participantMode = JanusState.participantMode;
    const isRoomPaid = paidList.includes(roomMode);
    const isParticipantPaid = paidList.includes(participantMode);

    console.log("isRoomPaid", isRoomPaid);
    console.log("isParticipantPaid", isParticipantPaid);

    if (this.isPaidChat && (!isRoomPaid || !isParticipantPaid)) {
      this.dispatch(setModals({ name: ENUM_MODALS_VALUE.tipsOffer, value: true }));
      this.isPaidChat = false;
    }

    this.isPaidChat = isRoomPaid && isParticipantPaid;
  }

  extraMess(changes) {
    const extra = changes?.extra;

    //reason
    const reason = extra?.reason;
    if (reason) {
      const mes = reason?.message;
      if (mes) {
        if (reason?.type === "not-enough-money") {
          errorToast(mes);
          this.dispatch(setModals({ name: ENUM_MODALS_VALUE.deposit, value: true }));
          return;
        }
        infoToast(mes);
      }
    }

    // warning
    const warningMes = extra?.warning?.message;
    if (warningMes) errorToast(warningMes);
  }

  #joiningModel(participant) {
    const mode = participant.chat_mode;
    JanusState.roomMode = mode;

    const status = modeByStatus[mode];
    console.log("status", status);
    console.log("setLoadingBroadcaster true");
    this.dispatch(setLoadingBroadcaster(true));
    this.dispatch(setChatActiveMode(mode));
    this.dispatch(setStatusWindow(status));

    // если у обоих одинаковый мод, но не пауза
    if (JanusState.participantMode === mode && mode !== ENUM_CHAT_MODE.pause) {
      this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.stream));
    }
  }

  // setOfflineModel - устанавливает модель в офлайн
  setOfflineModel() {
    this.isActive = false;
    console.log("status offline");
    this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.loading));
    this.dispatch(setChatActiveMode(ENUM_CHAT_MODE.pause));
    setTimeout(() => {
      if (JanusState.statusWindow === ENUM_STATUS_WINDOW.loading && !this.isActive) {
        console.log("status offline");
        this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.offline));
      }
    }, 10 * 1000);
  }

  // смена статуса на сервере
  participantChangeMode(participant) {
    const changesMode = participant.chat_mode;
    JanusState.participantMode = changesMode;
    this.isActive = true;
    this.#checkPaidChat();

    if (!changesMode) return;
    if (!this.userId || !participant.user_id) return;
    if (participant.user_id !== this.userId) return;
    if (changesMode === ENUM_CHAT_MODE.free) return;

    console.log(this.userId, participant.user_id, changesMode);

    // если нажал на стоп
    if (changesMode === ENUM_CHAT_MODE.pause) {
      if (!JanusState.chatActiveMode) return;

      console.log("JanusState.chatActiveMode", JanusState.chatActiveMode);
      if ([ENUM_CHAT_MODE.private, ENUM_CHAT_MODE.exclusive].includes(JanusState.chatActiveMode)) {
        console.log("status", ENUM_STATUS_WINDOW.pause);
        this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.pause));
        return;
      }

      console.log("status", modeByStatus[JanusState.chatActiveMode]);
      this.dispatch(setStatusWindow(modeByStatus[JanusState.chatActiveMode]));

      return;
    }

    console.log("set is me ", changesMode);
    this.dispatch(setChatActiveMode(changesMode));

    // выход, если статус уже стрим, что бы не было лишнего лодера
    if (JanusState.statusWindow === ENUM_STATUS_WINDOW.stream) return;

    this.dispatch(setLoadingBroadcaster(true));
    setTimeout(() => {
      JanusState.isRestore = true;
      console.log("status stream");
      this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.stream));
      this.dispatch(setChatActiveMode(changesMode));
    }, 0);
  }

  // onRemoteTrack - получение стрима с сервера
  async onRemoteTrack(janus, track, on) {
    console.log("onRemoteTrack", track, on);
    // продолжаем если трэк включен
    if (!(on && track)) return;
    console.log("get stream");
    JanusState.readyState = track.readyState;

    // если трэк закончен, то показываем лодер и дальше не идём
    if (track.readyState === "ended") {
      if (JanusState.statusWindow === ENUM_STATUS_WINDOW.stream) {
        console.log("setLoadingBroadcaster true");
        this.dispatch(setLoadingBroadcaster(true));
      }

      if (track.readyState === "live") {
        console.log("status", ENUM_STATUS_WINDOW.stream);
        this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.stream));
      }
      return;
    }

    const stream = new MediaStream([track]);
    if (track.kind === "audio") {
      await janus.attachMediaStream(JanusState.audioRef.current, stream); // attach audio
    } else {
      await janus.attachMediaStream(JanusState.videoRef.current, stream); // attach video
    }
  }

  // listener - прослушивать всех событий
  listener(clientChat, statuses) {
    clientChat
      .on("message", (_, msg) => {
        console.log("msg", msg);

        if ((msg.krona == "message" || msg.type === "message") && (msg.id || +msg.id === 0)) {
          this.dispatch(setNewMessages(msg));
        }
      })
      .on("message.remove", (remMes) => {
        this.dispatch(seRemMessages(remMes));
      })
      .on("remotetrack", this.onRemoteTrack.bind(this))
      // всем
      .on("room.change", async (room) => {
        console.log("room.change", room);
        const roomMode = room.chat_mode;
        const title = room?.title;
        if (roomMode) JanusState.roomMode = roomMode;

        this.#checkPaidChat();

        if (title) {
          const msg = { id: 0, type: "pin", title: title };
          this.dispatch(setMessagePin(msg));
        }

        if (!roomMode) return;

        const message = { mode: roomMode, type: TYPE_ENUM.switched };
        this.dispatch(setNewMessages(message));
        statuses.getSetting();

        // если только что модель подтвердила придёт в participant.change
        statuses.frontLogic(roomMode);
      }) // всем
      .on("room.switch", (request, room) => {
        console.log("switch", room);

        if (request.rejected || request.accepted) {
          dispatchCusEvent(ENUM_CUS_EVENT.responseSwitch, { request });
        } else {
          JanusState.requestId = request.id;
        }
      })

      .on("unpublished", (_, msg) => {
        console.log("unpublished", msg);
        if (msg.error) errorToast(msg.error);
      })
      .on("kicked", async (data) => {
        console.log("kicked", data);
        if (data.leaving !== "ok") return;
        JanusState.isLeave = true;
        JanusState.isRedirect = true;

        await this.navigate("/");

        const message = data.message || "You have been excluded from the chat";
        dispatchCusEvent(ENUM_CUS_EVENT.translationToast, { message, type: ENUM_TOAST_TYPE.error });

        this.destroyCurrent();
      })
      .on("leaved", (msg) => {
        console.log("leaved", msg);
        if (msg.user_id !== msg.room) return;

        const clientChat = JanusState.clientChat;
        if (clientChat?.settings?.ownVideoEnabled || clientChat.settings?.ownAudioEnabled) {
          console.log("unpublish");
          clientChat.localfeed.unpublish();
        }

        console.log("status offline");
        this.dispatch(setChatActiveMode(ENUM_CHAT_MODE.pause));
        this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.offline));
      })

      // только для этого пользователя
      .on("participant.change", (participant, changes) => {
        console.log("participant.change", participant, changes);
        const balance = changes.balance;
        const participantId = participant.user_id;

        if (participantId !== this.#userId) this.newUserId(participantId);
        if (balance && participantId === this.#userId) this.dispatch(setBalance(balance));
        if (participant) this.participantChangeMode(participant);

        //startStopByMode
        for (const feed of Object.values(JanusState.clientChat._remotefeeds)) {
          feed.startStopByMode(participant.chat_mode);
        }

        // mes

        this.extraMess(changes);
      })
      .on("error", (_, msg) => {
        console.log("error", msg);
        if (typeof msg == "string") errorToast(msg);
        if (typeof msg.error == "string") errorToast(msg.error);
      })
      .on("disconnect", () => {
        if (JanusState.isLeave) return;
        JanusState.disconnect();

        console.log("disconnect", this.roomId, this.chatId);

        if (this.roomId && this.chatId) this.disconnect.doReconnect(this.roomId, this.chatId);
        JanusDisconnect.reconnectEnabled = true;
      })
      .on("joined", (participant) => {
        console.log("joined", participant);
        const title = participant.description;

        if (title) {
          const msg = { id: 0, type: "pin", title: title };
          this.dispatch(setMessagePin(msg));
        }
      })
      .on("joining", async (participant) => {
        console.log("joining", participant);

        if (!this.chatId) {
          if (debugLog) errorToast("No chat Id on joining");
          return;
        }

        // подключился бродкастер
        if (participant.user_id === this.roomId) this.#joiningModel(participant);
      })
      .on("joined.connect", (result) => {
        console.log("joined.connect", result);

        const chatMode = result?.chat?.mode;
        const participantMode = result?.participant?.mode;

        JanusDisconnect.clearBadAttempts();

        if (!participantMode) return;

        const mode = modeByStatus[chatMode];
        const windowMode =
          participantMode === ENUM_CHAT_MODE.pause ? mode : ENUM_STATUS_WINDOW.stream;

        console.log("status", windowMode);
        this.dispatch(setStatusWindow(windowMode));
        this.dispatch(setChatActiveMode(chatMode));
        JanusState.participantMode = participantMode;
      })
      .on("ondataopen", () => {
        console.log("ondataopen");
        this.isActive = true;
        window.badAttempts = 0;
        statuses.getSetting();
      })
      .on("participant.login", (current, previous) => {
        console.log(current, previous);

        // при выходе/входе если текущий режим отличается от следующего
        if (current.user_id !== previous.user_id) {
          JanusState.participantMode = current.chat_mode;
        }

        if (current.user_id) {
          // при выходе
          const mode = modeByStatus[previous.chat_mode];
          console.log("mode", mode);
          console.log("JanusState.chatActiveMode", JanusState.chatActiveMode);

          if (mode === ENUM_STATUS_WINDOW.pause) return;
          this.dispatch(setStatusWindow(mode));
        }
      })
      .on("lovense.queue", (queue, running, waiting) => {
        console.log(queue, running, waiting);

        this.dispatch(setLovenseQueue(queue));
        this.dispatch(setLovenseRunning(running));
        this.dispatch(setLovenseWaiting(waiting));
      })
      .on("lovense.enabled", (enabled) => {
        this.dispatch(setLovenseEnable(enabled));
      })
      .on("lovense.settings", (levels, special) => {
        this.dispatch(setLovenseTips({ levels, special }));
      })
      .on("lovense.toys,", (toys) => {
        this.dispatch(setLovenseToys(toys));
      });
  }
}
