import axios from "axios";
import { errorToast, successToast } from "../components/mui/Toaster";
import { getShortLang } from "../utils/utils";

export const API_URL = "/api";
export const API_URL_USER = "/api/user";
export const API_URL_SITE = "/api/site";
export const API_URL_PAYMENT = "/api/payment";
export const API_URL_CHAT = "/api/chat";

export const PREFIX = window.startConfig?.site?.prefix || "";

axios.interceptors.response.use((response) => {
  if (response.data.message === "Unauthenticated.") {
    localStorage.removeItem("token");
    localStorage.clear();
    window.location.href = "/";
  }
  return response;
});

const messageByText = () => {
  const lang = getShortLang();

  const MESSAGES = {
    ru: {
      unknown: "Получена неизвестная ошибка",
      unexpected: "Неожиданные данные получены с сервера",
      notRespond: "Сервер не отвечает",
    },
    en: {
      unknown: "An unknown error was received",
      unexpected: "Unexpected data received from the server",
      notRespond: "The server is not responding",
    },
  };

  return MESSAGES[lang] || {};
};

export const cusAxios = (data) =>
  new Promise((resolve, reject) => {
    const messages = messageByText();
    axios(data)
      .then((response) => {
        const dataResp = response.data;
        const unStatusMes = dataResp?.message || messages.unknown;

        if (dataResp?.ok || dataResp?.status) {
          resolve(response);
          return;
        }
        reject(unStatusMes);
      })
      .catch((e) => reject(e.response?.data?.message || messages.unknown));
  });

export const thenMessageServer = (e) => {
  const messages = messageByText();

  const data = e.data;
  if (!data) return errorToast(messages.unexpected);

  if (data.status || data.ok) {
    if (data.message) successToast(data.message);
    return;
  }

  return errorToast(data.message || messages.unknown);
};

export const catchMessageServer = (e) => {
  if (typeof e === "string") return errorToast(e);
  const messages = messageByText();

  if (!e.response?.data) return errorToast(messages.notRespond);
  return errorToast(e.response?.data?.message || messages.unknown);
};
