import React from "react";
import IconCross from "../../images/jsIcons/IconCross";
import { useTranslation } from "react-i18next";

export default function CloseModal({ close }) {
  const { t } = useTranslation();
  return (
    <button
      className="modal__close"
      title={t("close")}
      onClick={() => {
        console.log("ff", close);

        close();
      }}
    >
      <IconCross />
    </button>
  );
}
